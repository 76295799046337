import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './atvxs/MainScreen';
import MienTrung from './atvxs/Mientrung';
import MienNam from './atvxs/Miennam';
import MienBac from './atvxs/Mienbac';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/mientrung" element={<MienTrung />} />
        <Route path="/miennam" element={<MienNam />} />
        <Route path="/mienbac" element={<MienBac />} />

      </Routes>
    </Router>
  );
};

export default App;
