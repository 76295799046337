import React from 'react';
import { useNavigate  } from 'react-router-dom';

const MainScreen = () => {
  const navigate = useNavigate();

  const handleMTImagePress = () => {
    navigate('/mientrung');
  };

  const handleMNImagePress = () => {
    navigate('/miennam');
  };

  const handleMBImagePress = () => {
    navigate('/mienbac');
  };

  return (
    <div style={styles.container}>
      <div style={styles.backgroundImage} />
      <div style={styles.content}>
        <div style={styles.imageContainer}>
          <img src="./img/head.png" alt="Header" style={styles.image} />
        </div>
        <h2 style={styles.headerText}>KẾT QUẢ XỔ SỐ MỚI NHẤT CẬP NHẬT MỖI NGÀY</h2>
        <div style={styles.topRow}>
          <button onClick={handleMNImagePress} style={{ ...styles.smallBox, backgroundImage: 'url("./img/mn.png")' }}>
          </button>
          <button onClick={handleMTImagePress} style={{ ...styles.smallBox, backgroundImage: 'url("./img/mt.png")' }}>
          </button>
        </div>
        <div style={styles.middleRow}>
          <button onClick={handleMBImagePress} style={{ ...styles.bigBox, backgroundImage: 'url("./img/mb.png")' }}>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    padding: '16px',
    textAlign: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundImage: `url('./img/bg.jpg')`,
    backgroundSize: 'cover',
    zIndex: -1,
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  imageContainer: {
    marginBottom: '20px',
  },
  image: {
    width: '120px',
    height: '120px',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '40px',
  },
  middleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '250px',
    marginTop: '50px',
  },
  
  headerText: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#333',
    margin: '10px 0',
  },
  smallBox: {
    height: '150px',
    width: '150px',
    borderRadius: '20px',
    margin: '0 10px',
    backgroundSize: 'cover',
  },
  bigBox: {
    height: '250px',
    width: '250px',
    borderRadius: '20px',
    marginTop: '20px',
    backgroundSize: 'cover',
  },
};

export default MainScreen;
