import React from 'react';

const Mienbac = () => {
  return (
    <iframe
      frameborder="0"
      height="900"
      scrolling="yes"
      src="https://xoso.mobi/embedded/kq-mienbac"
      width="100%"
      sandbox="allow-same-origin allow-scripts"
    ></iframe>
  );
};

export default Mienbac;
